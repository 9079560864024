.btn {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  cursor: pointer;
}

.btn span {
  display: block;
  width: 100%;
  box-shadow: 0 24x 0.2rem 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  height: 0.2rem;
  background: #AF986D;
  transition: all .3s;
  position: relative;
}

.btn span+span {
  margin-top: 0.2rem;
}

.active span:nth-child(1) {
  animation: ease .5s top forwards;
}

.not-active span:nth-child(1) {
  animation: ease .5s top-2 forwards;
}

.active span:nth-child(2) {
  animation: ease .5s scaled forwards;
}

.not-active span:nth-child(2) {
  animation: ease .5s scaled-2 forwards;
}

.active span:nth-child(3) {
  animation: ease .5s bottom forwards;
}

.not-active span:nth-child(3) {
  animation: ease .5s bottom-2 forwards;
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }

  50% {
    top: 0.4rem;
    transform: rotate(0);
  }

  100% {
    top: 0.4rem;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 0.4rem;
    transform: rotate(45deg);
  }

  50% {
    top: 0.4rem;
    transform: rotate(0deg);
  }

  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }

  50% {
    bottom: 0.4rem;
    transform: rotate(0);
  }

  100% {
    bottom: 0.4rem;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 0.4rem;
    transform: rotate(135deg);
  }

  50% {
    bottom: 0.4rem;
    transform: rotate(0);
  }

  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}