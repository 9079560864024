@import url('https://fonts.googleapis.com/css2?family=Martel+Sans:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@0;1&display=swap');

@font-face {
  font-family: 'BlaakLight';
  src: url('../assets/fonts/BlaakLight.ttf') format('truetype');
  /* Add more src lines for other font formats */
  /* font-weight: normal;
  font-style: normal; */
}

@font-face {
  font-family: 'BlaakBold';
  src: url('../assets/fonts/BlaakLight.ttf') format('truetype');
  /* Add more src lines for other font formats */
  /* font-weight: normal;
  font-style: normal; */
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Martel Sans';
}

input {
  color-scheme: dark;
}

body {
  line-height: 1.4;
  overflow-x: hidden;
  background-color: #000000;
}

a {
  color: #AF986D;
  text-decoration: none;
}

a:hover {
  color: white;
}

img {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
}

.content-container {
  margin-top: 7rem;
}

@media only screen and (max-width: 959px) {
  .content-container {
    margin-top: 0rem;
  }
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: grey;
}

/* Remove Spinner Arrow*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}